import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//Importing Styles
import './footerStyle.css'

//Importing Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'

//Importing Assets
import LOGO from '../../Assets/images/FinEra_LOGO_Darkmode.png'

//Importing Components
import DMLM_Button from '../DMLM_Button/DMLM_Button'

function Footer({ states, toggleModes }) {
    //Variables
    const [copyrightYear, setcopyrightYear] = useState(0)

    //Functions
    const getcopyYear = () => {
        let date = new Date()

        return date.getUTCFullYear()
    }
    useEffect(() => {
        setcopyrightYear(getcopyYear)
    }, [copyrightYear])
    return (
        <>
            <footer className='footer_container'>
                <div className='row1'>
                    <div className='company_content'>
                        <h4>Quick Links</h4>
                        <ul>
                            <li><Link onClick={() => { states('.bannerContainer') }} to="/">Home</Link></li>
                            <li><Link to="/services&pricing">Services</Link></li>
                            <li><Link onClick={() => { states('#about') }} to="/">About</Link></li>
                            <li><Link onClick={() => { states('.faq_section') }} to="/">FAQ's</Link></li>
                            <li><Link onClick={() => { states('#contact') }} to="/">Contact</Link></li>
                        </ul>
                    </div>
                    <div className='services_content'>
                        <h4>Services</h4>
                        <ul>
                            <li><Link to='/services&pricing' state={{ category: 'Registrations' }}>Registrations</Link></li>
                            <li><Link to='/services&pricing' state={{ category: 'Licenses' }}>Licenses</Link></li>
                            <li><Link to='/services&pricing' state={{ category: 'TDS Preparation' }}>TDS Preparations</Link></li>
                            <li><Link to='/services&pricing' state={{ category: 'GST Filing' }}>GST Filing</Link></li>
                            <li><Link to='/services&pricing' state={{ category: 'IT Filing' }}>IT Filing</Link></li>
                            <li><Link to='/services&pricing'>All Services</Link></li>
                        </ul>
                    </div>
                    <div id='contact' className='contactUs'>
                        <h4> Contact Us </h4>
                        <div className='contact_content'>
                            <p><FontAwesomeIcon className='icons' icon={faPhone}></FontAwesomeIcon> +91 9007633095</p>
                            <p><FontAwesomeIcon className='icons' icon={faEnvelope}></FontAwesomeIcon> contact@finera.net.in</p>
                        </div>
                        {/* <DMLM_Button toggleModes={toggleModes}></DMLM_Button> */}
                    </div>
                </div>

                <div className='row2'>
                    <div className='logo_container'>
                        <Link onClick={() => { states('.newBannerContainer') }} className="" to="/"><img src={LOGO}></img></Link>
                    </div>

                    <div className='content'>
                        <h4 className="">Follow Us</h4>
                        <ul className="">
                            <li className=""><a className="" href="https://www.instagram.com/finera.net.in/" target='blank'><i className="fa fa-instagram" aria-hidden="true" /></a></li>
                            <li className=""><a className="" href="https://api.whatsapp.com/send?phone=919007633095" target='blank'><i className="fa fa-whatsapp" aria-hidden="true" /></a></li>
                            <li className=""><a className="" href="https://www.linkedin.com/company/fineraonline/about/" target='blank'><i className="fa fa-linkedIn" aria-hidden="true" /></a></li>
                            <li className=""><a className="" href="https://maps.app.goo.gl/iE6M6jQmdTNfegoP7" target='blank'><i className="fa fa-google" aria-hidden="true" /></a></li>
                        </ul>
                    </div>
                </div>
            </footer>

            <div className="footer_footing">
                <p>Copyright © {copyrightYear} Finera | Powered by Raza and Associates.</p>
            </div>
        </>
    )
}

export default Footer