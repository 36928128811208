import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'

//Importing Styles
import './requestformStyle.css'
import './thankyouStyle.css'

//Importing FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

//Importing Loader
import { Hourglass } from 'react-loader-spinner'
import { ThreeDots } from 'react-loader-spinner'

//Importing GSAP for Animation
import gsap from 'gsap'; // <-- import GSAP
import { useGSAP } from '@gsap/react'; // <-- import the hook from our React package

gsap.registerPlugin(useGSAP)

function RequestCallbackForm({ state, func, serviceType }) {
  // Create an instance of AbortController
  const controller = new AbortController();

  //Refs
  const phoneInput = useRef(null)
  const formContainer = useRef(null)

  //States
  const [loader, setloader] = useState(false)
  const [thankYou, setthankYou] = useState(false) //Thank You Form
  const [serverResponse, setserverResponse] = useState(null)

  //Variables
  const [name, setname] = useState("")
  const [email, setemail] = useState("")
  const [phoneNo, setphoneNo] = useState("")
  const [message, setmessage] = useState("")
  const [submitActive, setsubmitActive] = useState(false)
  const [validator, setvalidator] = useState({
    name: false,
    email: false,
    phone: false,
    message: false
  })

  //Animation
  useGSAP((context, contextSafe) => {

    if (formContainer.current != null) {
      const tl = gsap.timeline()
      tl.from(formContainer.current, { scale: 0, opacity: 0.3, duration: 0.3, ease: 'back.out(3)', delay: 0.1 })
        .from(formContainer.current.querySelectorAll('.form .row'), { y: -20, opacity: 0, duration: 0.3, stagger: 0.1 })

      const onClick = contextSafe(() => {
        if (controller) {
          controller.abort()
        }
        tl.reverse()
        setTimeout(() => {
          handleFormDisplay()
        }, 1100)

      });

      formContainer.current.querySelector('.cancelButton').addEventListener('click', onClick);

      // 👍 we remove the event listener in the cleanup function below.
    }
  },
    { scope: formContainer }
  )

  //Functions
  const handleFormDisplay = () => {
    if (state.status == true) {
      func()
    }
  }

  const handleValidation = (validatorTarget, identifier) => {
    const temp = validator
    if (identifier == 'phone') {
      temp[identifier] = String(validatorTarget.value).length == 10 ? true : false
    } else {
      temp[identifier] = validatorTarget.checkValidity() == true ? true : false
    }
    setvalidator(temp)

    const keys = Object.keys(validator)
    let count = 0
    keys.forEach((key, idx) => {
      if (validator[key] == true) {
        count = count + 1
      }
    })

    if (count == 4) {
      setsubmitActive(true)
    } else {
      setsubmitActive(false)
    }
  }

  const handleNameInput = (e) => {
    setname(e.target.value)
    handleValidation(e.target, 'name')
  }
  const handleEmailInput = (e) => {
    setemail(e.target.value)
    handleValidation(e.target, 'email')
  }
  const handlePhoneInput = (e) => {
    if (e.target.value.length < 11) {
      setphoneNo(e.target.value)
      handleValidation(e.target, 'phone')
    }
    else {
      return
    }
  }
  const handleMessageInput = (e) => {
    setmessage(e.target.value)
    handleValidation(e.target, 'message')
  }

  const handleSubmit = async () => {
    //Validation
    if (phoneNo.length < 10) {
      alert('Please give a Valid Phone Number')
      phoneInput.current.focus()
    } else {
      //Further Processing
      setloader(true)

      try {
        const response = await fetch('https://api.finera.net.in/api/v1/contact/requestCallback', {
          method: "POST",
          body: JSON.stringify({ name: name, email: email, phoneNo: phoneNo, serviceType: serviceType, message: message }),
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal
        }).then((res) => res.json())

        if (response.success === true) {
          setloader(false)
          setthankYou(true)
          setserverResponse(null)
        } else if (response.success === false && response.message === "Message not Sent") { //Don't Change the Message Condition
          setloader(false)
          setthankYou(true)
          setserverResponse({ heading: response.message, message: response.content })
        } else {
          setloader(false)
          setthankYou(true)
          setserverResponse({ heading: 'Message Not Sent', message: 'Internal Server Problem, sorry for your inconvenience. We are Looking into the matter.' })
        }
      } catch (error) {
        setloader(false)
        setthankYou(true)
        setserverResponse({ heading: 'Message Not Sent', message: 'Internal Server Problem, sorry for your inconvenience. We are Looking into the matter.' })
      }
    }
  }

  return (

    <>
      {
        !thankYou ? (<div ref={formContainer} className='formContainer'>
          <div className='cancelButton'>
            <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
          </div>

          <div className='heading'>
            <h2>Request a Callback</h2>
            <p>Thanks for your interest in our services. Our team will give you a call within 24 hours to discuss your case in more detail.</p>
          </div>

          <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }} method='post' className='form'>
            <div className='row'>
              <label>Name<span style={{ color: '#ff4824' }}>*</span></label>
              <input type='text' placeholder='Enter your Name' value={name} onChange={(e) => handleNameInput(e)} required></input>
            </div>

            <div className='row'>
              <label>Email<span style={{ color: '#ff4824' }}>*</span></label>
              <input type='email' placeholder='Enter your Email' value={email} onChange={(e) => handleEmailInput(e)} required></input>
            </div>

            <div className='row'>
              <label>Phone Number<span style={{ color: '#ff4824' }}>*</span></label>
              <input type='number' ref={phoneInput} placeholder='Enter your Phone Number' value={phoneNo} onChange={(e) => handlePhoneInput(e)} required></input>
            </div>

            <div className='row'>
              <label>Service Type</label>
              <input type='text' placeholder='Service Type' value={serviceType} readOnly></input>
            </div>

            <div id='message' className='row'>
              <label>Message</label>
              <textarea rows='4' placeholder='Enter your Message' value={message} onChange={(e) => handleMessageInput(e)}></textarea>
            </div>

            {
              !loader ? (
                <div id='submit' className='row'>
                  <input type='submit' disabled={submitActive ? false : true} value={'SUBMIT'} className={submitActive ? 'active' : ''}></input>
                </div>
              ) : (
                <div id='submit' className='row'>
                  <div className='loader'>
                    <ThreeDots
                      visible={true}
                      height="25"
                      width="25"
                      color="rgba(57, 223, 132, 0.600)"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                </div>
              )
            }
          </form>
        </div>) : (<div className='thankyouContainer'>
          <div className='heading'>
            {
              serverResponse == null ? (
                <>
                  <h2>Thank you for contacting us!</h2>
                  <p>We have received your request.<br />Our team will reach you within less than 24 hours.</p>
                </>
              ) : (
                <>
                  <h2>{serverResponse.heading}</h2>
                  <p>{serverResponse.message}</p>
                </>
              )
            }
          </div>

          <div className='footing'>
            {/* <button onClick={handleFormDisplay}>OK</button> */}
            <Link to='/' onClick={(e) => { e.preventDefault(); setthankYou(false); handleFormDisplay() }}>OK</Link>
          </div>
        </div>)
      }
    </>

  )
}

export default RequestCallbackForm