import React, { useState } from 'react'
import './DMLM_ButtonStyle.css'

function DMLM_Button({toggleModes}) {

    //Functions
    const handleToggle = () => {
        toggleModes.func()
    }

    return (
        <>
            <div className='cmButton_container'>
                {/* <h4>Dark Mode</h4> */}
                <div onClick={handleToggle} className={ toggleModes.state == "light" ? 'toggle_button_container' : 'toggle_button_container dark'}>
                    <div className={ toggleModes.state == "light" ? 'shape_circle' : 'shape_circle dark'}></div>
                </div>
            </div>
        </>
    )
}

export default DMLM_Button