//React Router DOM
import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { useEffect, useRef } from 'react';

//Importing Layout
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';

//Importing Styles
import './App.css'

//Importing Pages
import Homepage from './Pages/HomePage/Homepage';
import Services_Pricing from './Pages/Services&Pricing/Services&Pricing';
import { useState } from 'react';

function App() {
  //States
  const [_id, set_id] = useState(null)

  //Variables
  const [toggleMode, settoggleMode] = useState("dark")

  //Functions
  const smoothScroll = (_id) => {
    if (_id !== null) {
      const elem = document.querySelector(_id)
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
  }

  const handleToggle = () => {
    if (toggleMode == "light") {
      settoggleMode("dark")
      localStorage.setItem("theme_mode", "dark")
    } else {
      settoggleMode("light")
      localStorage.setItem("theme_mode", "light")
    }
  }

  //Locations
  const location = useLocation();

  useEffect(() => {
    const executeAfterRouteChange = () => {
      // Your function to execute after route change
      if (_id !== null) {
        setTimeout(() => {
          smoothScroll(_id);
        }, 300);
      }
      set_id(null)
    };

    executeAfterRouteChange();
  }, [location, _id]);

  useEffect(() => {
    const theme = localStorage.getItem("theme_mode")

    if(!theme){
      localStorage.setItem("theme_mode", "dark")
      settoggleMode("dark")
    } else {
      settoggleMode(theme)
    }
  })

  return (
    <>
      <Header states={set_id} toggleModes={{ state: toggleMode, func: handleToggle }} />
      <main className={toggleMode == "light" ? "body_container" : "body_container dark"}>
        <Routes>

          <Route path='/' element={<Homepage states={set_id} toggleModes={{state: toggleMode}}/>} />
          <Route path='/services&pricing' element={<Services_Pricing toggleModes={{state: toggleMode}}/>} />

        </Routes>
      </main>
      <Footer states={set_id} toggleModes={{ state: toggleMode, func: handleToggle }} />
    </>
  );
}

export default App;
