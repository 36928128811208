import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import './services&PricingStyle.css'

//Importing Components
import RequestCallbackForm from '../../Components/Contact/RequestCallbackForm'

//Importing Assets
import BANNERDARK from '../../Assets/images/Servies and Pricing/services_page_dark.png'
import BANNERLIGHT from '../../Assets/images/Servies and Pricing/services_page_light.png'

//Importing DATA
import { servicesDATA } from './DATA'

//Importing GSAP for Animation
import gsap from 'gsap'; // <-- import GSAP
import { useGSAP } from '@gsap/react'; // <-- import the hook from our React package

gsap.registerPlugin(useGSAP)

function Services_Pricing({toggleModes}) {
    //Route
    const route = useLocation()

    //Data From Route
    const state = route ? route.state : null

    //Refs
    const servicesContainer = useRef(null)

    //Variables
    const [fixed, setfixed] = useState(false)
    const [active, setactive] = useState(state != null ? state.category : 'Registrations')
    let container = useRef(null);
    let [height, setHeight] = useState(null);

    const [requestFormDisplay, setrequestFormDisplay] = useState({ status: false, data: '' })
    const [searchBarDisplay, setsearchBarDisplay] = useState(false)

    const [searchedDATA, setsearchedDATA] = useState([])
    const [searchValue, setsearchValue] = useState(route ? route.state ? route.state.searchItem : "" : "")

    //Animation

    //Functions
    const handleCategoryPress = (_id) => {
        setactive(_id)
        setsearchValue('')
        setsearchedDATA([])
    }

    const searchInput = (value) => {
        setsearchValue(value)

        //Searching Logic
        let input = value
        var queryData = []
        servicesDATA.forEach((elems, idx) => {
            var temp = elems.content.filter(
                (item) => {
                    const pattern = new RegExp(input, "gi")
                    if (pattern.exec(item.title) != null) {
                        return true
                    } else {
                        return false
                    }
                }
            )

            if (temp.length != 0) {
                temp.forEach((obj) => {
                    queryData.push(obj)
                })
            }
        })

        console.log(queryData)
        setsearchedDATA(queryData)
        if (value != "") {
            setactive('')
        } else {
            setactive('Registrations')
            setsearchedDATA([])
        }
    }

    const handleFixedSidebar = () => {
        if (window.scrollY >= 350 && window.scrollY <= (height - 502)) {
            setfixed(true)
        } else {
            setfixed(false)
        }

        //Displaying Search Bar in Sidebar
        if (window.scrollY >= 243) {
            setsearchBarDisplay(true)
        } else {
            setsearchBarDisplay(false)
        }
    }
    window.addEventListener('scroll', handleFixedSidebar)

    const handleRequestForm = (serviceType = '') => {
        if (requestFormDisplay.status) {
            setrequestFormDisplay({ status: false, data: '' })
            //UnLock Scroll
            document.body.style.overflow = '';
        } else {
            setrequestFormDisplay({ status: true, data: serviceType })
            //Lock Scroll
            document.body.style.overflow = 'hidden';
        }
    }

    const calculateGrossAmount = (amount, discountAmount) => {
        return amount + discountAmount
    }

    useEffect(() => { window.scrollTo(0, 0); if (route.state && route.state.searchItem) { searchInput(route.state.searchItem) } }, []);

    useEffect(() => {
        setHeight(container.current.offsetHeight);
        handleFixedSidebar();
    }, [active])

    return (
        <>
            {
                requestFormDisplay.status && (<div id='requestFormContainer'>
                    <RequestCallbackForm state={requestFormDisplay} func={handleRequestForm} serviceType={requestFormDisplay.data}></RequestCallbackForm>
                </div>)
            }
            {/* Top Banner */}
            <div className={toggleModes.state == "light" ? 'bannerSection' : 'bannerSection dark'} style={{backgroundImage: `url(${toggleModes.state == "light" ? BANNERLIGHT : BANNERDARK})`}}>
                <div className='container'>
                    <h4>Our Services</h4>
                    <div className='searchBar'>
                        <i className="fa fa-magnifying-glass" aria-hidden="true" />
                        <input type='search' placeholder='Type here to Search' value={searchValue} onChange={(e) => searchInput(e.target.value)}></input>
                    </div>
                </div>
            </div>

            <div className={toggleModes.state == "light" ? 'main_container' : 'main_container dark'} ref={container}>
                {/* Side Bar */}
                <div className={toggleModes.state == "light" ? fixed ? 'sidebar_container fixed' : 'sidebar_container' : fixed ? 'sidebar_container fixed dark' : 'sidebar_container dark'}>
                    <h4><i class="fa fa-list"></i> Categories</h4>
                    <ul>
                        {
                            servicesDATA.map((obj, i) => (
                                <li key={i} onClick={() => handleCategoryPress(obj.category)} className={active == obj.category ? 'active' : ''}>{obj.category}</li>
                            ))
                        }
                    </ul>

                    <div className='searchBar' style={searchBarDisplay ? { display: 'flex' } : { display: 'none' }}>
                        <i className="fa fa-magnifying-glass" aria-hidden="true" />
                        <input type='search' placeholder='Search for service and hire an Expert' value={searchValue} onChange={(e) => searchInput(e.target.value)}></input>
                    </div>
                </div>

                {/* Top Bar for Mobile Screens */}
                <div className={toggleModes.state == "light" ? fixed ? 'topbar_container fixed' : 'topbar_container' : fixed ? 'topbar_container fixed dark' : 'topbar_container dark'}>
                    <h4><i class="fa fa-list"></i> Categories</h4>
                    <ul>
                        {
                            servicesDATA.map((obj, i) => (
                                <li key={i} onClick={() => handleCategoryPress(obj.category)} className={active == obj.category ? 'active' : ''}>{obj.category}</li>
                            ))
                        }
                    </ul>

                    <div className='searchBar' style={searchBarDisplay ? { display: 'flex' } : { display: 'none' }}>
                        <i className="fa fa-magnifying-glass" aria-hidden="true" />
                        <input type='search' placeholder='Search for service and hire an Expert' value={searchValue} onChange={(e) => searchInput(e.target.value)}></input>
                    </div>
                </div>

                {/* Services Container */}
                <div ref={servicesContainer} className={toggleModes.state == "light" ? fixed ? 'services_container fixed' : 'services_container' : fixed ? 'services_container fixed dark' : 'services_container dark'}>
                    {
                        active && servicesDATA[servicesDATA.findIndex((obj) => { return obj.category === active })].content.map((obj, idx) => (
                            <div key={idx} className='card'>
                                <h4>{obj.title}</h4>
                                {
                                    obj.t_c && <h5>Starting From</h5>
                                }
                                <h2>&#8377; {obj.amount}/- {obj.t_c && <span className='t_cMark'>*</span>}
                                    {
                                        obj.basis ? (
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                <span className='discountAmount'> &#8377;{calculateGrossAmount(obj.amount, obj.discount)}/-</span>
                                                <span> {
                                                    obj.basis === 'monthly' ? 'per month' : obj.basis === 'quarterly' ? 'per quarter' : 'per year'
                                                }</span>
                                            </div>
                                        ) : (
                                            <>
                                                <span className='discountAmount' style={{ alignSelf: 'flex-end', paddingBottom: '5px' }}> &#8377;{calculateGrossAmount(obj.amount, obj.discount)}/-</span>
                                            </>
                                        )
                                    }
                                </h2>
                                <div className='buttons'>
                                <a onClick={() => handleRequestForm(obj.title)}>Request A Callback</a>
                                <a>Know More</a>
                                </div>
                            </div>
                        ))
                    }
                    {
                        searchedDATA.length != 0 && searchedDATA.map((obj, idx) => (
                            <div key={idx} className='card'>
                                <h4>{obj.title}</h4>
                                <h2>&#8377; {obj.amount}/- {obj.t_c && <span className='t_cMark'>*</span>}
                                    {
                                        obj.basis ? (
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                <span className='discountAmount'> &#8377;{calculateGrossAmount(obj.amount, obj.discount)}/-</span>
                                                <span> {
                                                    obj.basis === 'monthly' ? 'per month' : obj.basis === 'quarterly' ? 'per quarter' : 'per year'
                                                }</span>
                                            </div>
                                        ) : (
                                            <span className='discountAmount' style={{ alignSelf: 'flex-end', paddingBottom: '5px' }}> &#8377;{calculateGrossAmount(obj.amount, obj.discount)}/-</span>
                                        )
                                    }
                                </h2>
                                <div className='buttons'>
                                    <a onClick={() => handleRequestForm(obj.title)}>Request A Callback</a>
                                    <a>Know More</a>
                                </div>
                            </div>
                        ))
                    }
                    <div className='t_c'>
                        <p>* T&C applied</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services_Pricing