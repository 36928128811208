//DATA
const servicesDATA = [
    {
        category: 'Registrations',
        content: [
            {
                title: 'PAN Registration',
                amount: 149,
                t_c: true,
                discount: 200
            },
            {
                title: 'TAN Registration',
                amount: 499,
                discount: 300
            },
            {
                title: 'GST Registration',
                amount: 1800,
                discount: 500
            },
            {
                title: 'MSME Registration',
                amount: 599,
                discount: 300
            },
            {
                title: 'Private Ltd Registration',
                amount: 8499,
                discount: 3400
            },
            {
                title: 'LLP Company Registration',
                amount: 8499,
                discount: 4250
            },
            {
                title: 'Brand Registration',
                amount: 2999,
                discount: 1000,
                t_c: true
            },
            // {
            //     title: 'Trademark Registration',
            //     amount: 3499,
            //     discount: 400
            // },
            {
                title: 'Digital Signature',
                amount: 1799,
                discount: 900
            },
            {
                title: 'NGO Darpan Registration',
                amount: 1999,
                discount: 1000
            },
        ]
    },
    {
        category: 'Licenses',
        content: [
            {
                title: 'Trade License',
                amount: 999,
                discount: 200
            },
            {
                title: 'Import & Export License',
                amount: 1199,
                t_c: true,
                discount: 250
            },
            {
                title: 'Fire License',
                amount: 1199,
                discount: 150
            },
            {
                title: 'Food License',
                amount: 1499,
                discount: 500
            },
        ]
    },
    {
        category: 'TDS Preparation',
        content: [
            {
                title: 'TDS on Sale of Property',
                amount: 1299,
                discount: 400
            },
            {
                title: 'TDS on Salary',
                amount: 1299,
                discount: 500
            },
            {
                title: 'TCS Payments',
                t_c: true,
                amount: 1299,
                discount: 250
            },
            {
                title: 'TDS on Rent',
                amount: 1299,
                discount: 300
            },
            {
                title: 'TDS on Other Payments',
                amount: 1499,
                t_c: true,
                discount: 200
            },
        ]
    },
    {
        category: 'GST Filing',
        content: [
            {
                title: 'Monthly GSTR 3B and GSTR 1 Filing',
                amount: 999,
                t_c: true,
                basis: 'monthly',
                discount: 400
            },
            {
                title: 'Quaterly GSTR 3B and GSTR 1 Filing',
                amount: 2499,
                t_c: true,
                basis: 'quarterly',
                discount: 1500
            },
            {
                title: 'GSTR-9 Filing',
                amount: 2499,
                t_c: true,
                discount: 500
            },
            {
                title: 'GSTR-9C Filing with Tax Audit',
                amount: 2999,
                t_c: true,
                discount: 1500
            }
        ]
    },
    {
        category: 'IT Filing',
        content: [
            {
                title: 'Single Salary',
                amount: 899,
                discount: 400
            },
            {
                title: 'Multiple Salaries',
                amount: 1499,
                t_c: true,
                discount: 750
            },
            {
                title: 'Complex / Capital Gains',
                amount: 3499,
                t_c: true,
                discount: 2000
            },
            {
                title: 'Small Business',
                amount: 999,
                discount: 500
            },
            {
                title: 'Business Filing',
                amount: 3999,
                discount: 2000
            },
            {
                title: 'Partnership Firm Filing',
                amount: 4499,
                t_c: true,
                discount: 2250
            },
            {
                title: 'NRI IT Filing',
                amount: 2299,
                t_c: true,
                discount: 1100
            },
            {
                title: 'Resident with Foreign Income',
                amount: 4999,
                t_c: true,
                discount: 2000
            },
            {
                title: 'Futures & Options',
                amount: 3999,
                discount: 1500
            },
            {
                title: 'Futures & Options with Tax Audit',
                amount: 14999,
                discount: 7500
            },
            {
                title: 'Cryptos & Bitcoins',
                amount: 4999,
                t_c: true,
                discount: 2500
            },
            {
                title: 'Notice Handling',
                amount: 999,
                discount: 500
            },
            {
                title: 'Tax Consultation',
                amount: 699,
                t_c: true,
                discount: 300
            },
            {
                title: 'LLP IT + ROC Filing',
                amount: 12999,
                discount: 7000
            },
            {
                title: 'Pvt Ltd IT + ROC + Audit Filing',
                amount: 14999,
                t_c: true,
                discount: 7500
            },
            {
                title: 'Form 15CA / 15CB Filing',
                amount: 4000,
                discount: 2000
            },
            {
                title: 'Updated Return u/s 139(8A)',
                amount: 1799,
                t_c: true,
                discount: 350
            },
            {
                title: 'Defective Notice u/s 139(9)',
                amount: 799,
                t_c: true,
                discount: 400
            },
            {
                title: 'Form 13 (NIL TDS) Filing',
                amount: 4799,
                discount: 2400
            },
        ]
    },
]

export { servicesDATA }