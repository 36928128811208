import React, { useRef, useState } from 'react'
import './SideButtonsStyle.css'

//Importing FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'

//Importing GSAP for Animation
import gsap from 'gsap'; // <-- import GSAP
import { useGSAP } from '@gsap/react'; // <-- import the hook from our React package

gsap.registerPlugin(useGSAP)

function SideButtons({ toggleModes }) {
    //Refs
    const faqContainer = useRef(null)

    //States
    const [faqActive, setfaqActive] = useState(false)

    //Animation
    useGSAP(
        (context, contextSafe) => {
            // gsap code here...
            const tl = gsap.timeline()
            tl.fromTo(faqContainer.current, { bottom: '-60vh' }, { bottom: 0, duration: 0.4 })
        },
        [faqActive]
    ); // <-- scope for selector text (optional)

    //Functions
    const handleFaqToggle = () => {
        if (!faqActive) {
            setfaqActive(true)
        }
        else {
            gsap.to(faqContainer.current, { bottom: '-60vh', duration: 0.4, })
            setTimeout(() => {
                setfaqActive(false)
            }, 500)
        }
    }

    return (
        <>
            <div className={toggleModes.state == "light" ? 'sidebuttons_container' : 'sidebuttons_container dark'}>
                <div className='buttons' onClick={handleFaqToggle}>
                    <FontAwesomeIcon icon={faCircleQuestion} id='icon'></FontAwesomeIcon>
                    <h6>FAQs</h6>
                </div>
                <a href="https://api.whatsapp.com/send?phone=919007633095" target='blank'>
                    <div className='buttons'>
                        <FontAwesomeIcon icon={faWhatsapp} id='whatsapp'></FontAwesomeIcon>
                        {/* <h6>Whatsapp</h6> */}
                    </div>
                </a>
            </div>

            {
                faqActive && (
                    <div ref={faqContainer} className={toggleModes.state == "light" ? 'faq_container' : 'faq_container dark'}>
                        <div className='button_container'>
                            <div className='button' onClick={handleFaqToggle}>
                                <FontAwesomeIcon icon={faAngleDown} />
                            </div>
                        </div>
                        <div className='content_container'>
                            <h3>FAQs</h3>
                            <div className='content'>
                                <div>
                                    <input type='checkbox' id='number_one'></input>
                                    <label htmlFor='number_one'>
                                        <div className='icon'>
                                            <FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon>
                                        </div>
                                        <p>What is Finera?</p>
                                    </label>
                                    <div className='answer_content'>
                                        <p>
                                            <strong>Finera</strong> is the brand of Raza And Associates, a dynamic startup dedicated to providing comprehensive legal and financial solutions for businesses. Our services encompass a wide range of essential business needs, including GST registration and filing, Income Tax Return (ITR) filing, TDS compliance, trade licenses, food licenses, and various other legal solutions. At Finera, we strive to ensure that businesses navigate complex regulatory requirements with ease, allowing them to focus on their core operations. Our expert team is committed to delivering personalized and reliable support to help your business achieve compliance and growth.
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <input type='checkbox' id='number_two'></input>
                                    <label htmlFor='number_two'>
                                        <div className='icon'>
                                            <FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon>
                                        </div>
                                        <p>
                                            What services does Finera offer?
                                        </p>
                                    </label>
                                    <div className='answer_content'>
                                        <p>
                                            <strong>Finera</strong> offers a comprehensive range of legal solutions tailored for businesses. Our services include: <br /><br />

                                            1. GST Registration and Filing: Assisting businesses in registering for GST and managing their regular GST filings.<br />
                                            2. Income Tax Return {"(ITR)"} Filing: Providing expert support for accurate and timely ITR filing.<br />
                                            3. TDS Compliance: Ensuring businesses meet their TDS obligations with thorough preparation and filing.<br />
                                            4. Licensing Services: Facilitating the acquisition of essential business licenses, such as Trade Licenses and Food Licenses.<br />
                                            5. Many More: Offering a variety of additional legal and financial services to meet the unique needs of your business.
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <input type='checkbox' id='number_three'></input>
                                    <label htmlFor='number_three'>
                                        <div className='icon'>
                                            <FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon>
                                        </div>
                                        <p>
                                            What is the application process of any service with Finera?
                                        </p>
                                    </label>
                                    <div className='answer_content'>
                                        <p>When a customer opts for a service with Finera, they can begin the application process by clicking on the <strong>"Request a Callback"</strong> option from the services menu. The customer will then fill out a form with their <strong>phone number, email ID, name, and any additional message</strong>. Once the form is submitted, <strong><em>our dedicated team will contact the customer within 24 hours via email or phone to discuss the service further and initiate the process.</em></strong><br /><br />

                                            At <strong>Finera</strong>, we pride ourselves on our commitment to customer satisfaction and transparency. Our experienced professionals ensure that each step of the process is handled with the utmost care and efficiency. We understand the importance of trust in choosing a legal and financial partner, and we strive to build lasting relationships with our clients by delivering reliable and high-quality services.<br /><br />

                                            Alternatively, customers are welcome to visit our office directly to speak with our team and start the application process in person. We are always ready to provide personalized support and address any questions or concerns.</p>
                                    </div>
                                </div>
                                <div>
                                    <input type='checkbox' id='number_four'></input>
                                    <label htmlFor='number_four'>
                                        <div className='icon'>
                                            <FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon>
                                        </div>
                                        <p>How long does it take to complete my application?</p>
                                    </label>
                                    <div className='answer_content'>
                                        <p>
                                            The duration of the application process at Finera varies based on the specifics of each case and individual circumstances. Our team of experts will conduct a thorough assessment of your situation to provide you with an accurate estimate of the time required. We understand that time is of the essence, and our team is committed to working tirelessly to address your needs efficiently. After evaluating your case, we will communicate all necessary details and timelines to ensure you have a clear understanding of the process ahead. We are dedicated to providing timely and effective solutions while prioritizing the unique needs of your application.
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <input type='checkbox' id='number_five'></input>
                                    <label htmlFor='number_five'>
                                        <div className='icon'>
                                            <FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon>
                                        </div>
                                        <p>Why should I choose Finera for my business’s legal solutions?</p>
                                    </label>
                                    <div className='answer_content'>
                                        <p>
                                            Choosing Finera for your business's legal solutions means partnering with a dedicated team of professionals who prioritize your success. Our comprehensive range of services is designed to meet the diverse needs of businesses at every stage. With our expertise in tax consultancy, compliance, and licensing, we provide tailored solutions that ensure your business operates smoothly and efficiently. Our commitment to exceptional customer service and transparency sets us apart, as we strive to build lasting relationships based on trust and reliability. By choosing Finera, you can focus on growing your business while we handle the complexities of legal matters.
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <input type='checkbox' id='number_six'></input>
                                    <label htmlFor='number_six'>
                                        <div className='icon'>
                                            <FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon>
                                        </div>
                                        <p>How can I contact Finera for support or queries?</p>
                                    </label>
                                    <div className='answer_content'>
                                        <p>
                                            You can easily contact Finera for support or any queries by visiting our <a className='' href=''><strong>"Contact Us"</strong></a> section on the website. Here, you can submit your inquiries related to our services by providing your basic details, such as your email address and name. Our dedicated team will respond to you within 24 hours, ready to address all your questions and provide the assistance you need. We are here to help and ensure you have a seamless experience with our services.
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <input type='checkbox' id='number_seven'></input>
                                    <label htmlFor='number_seven'>
                                        <div className='icon'>
                                            <FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon>
                                        </div>
                                        <p>What makes Finera different from other tax consultancy firms?</p>
                                    </label>
                                    <div className='answer_content'>
                                        <p>
                                            Finera stands out from other tax consultancy firms due to our unwavering commitment to personalized service and customer satisfaction. Our team of experienced professionals takes the time to understand each client's unique needs and challenges, allowing us to deliver tailored solutions that truly address their requirements.<br /><br />

                                            Additionally, we prioritize transparency and communication, ensuring that our clients are informed and involved throughout the process. Our comprehensive range of services—from GST registration to ITR filing—coupled with our proactive approach to compliance, means we not only meet regulatory requirements but also help our clients achieve long-term financial success.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default SideButtons